import React from "react";

import * as Classes from './threeUp.module.scss'

const ThreeUp = ({title, elements}) => {

    return (
        <section className="">
            <div className="container">
                <div className="row flex center-xs space-big">
                    <div className="col col-xs-12">
                        <h2 className="text-center smallPaddingBottom">{title}</h2>
                    </div>
                </div>
                <div className="row text-center">
                {elements.map((s, i) => {
                    return (
                    <div className={["col col-xs-6 col-lg-4 space-big col-lg-push-0 col-lg-pull-0", i == 2 || i == 6 ? 'col-xs-push-6' : i == 3 || i == 7 ? 'col-xs-pull-6' : ''].join(' ')} key={i}>
                        <img className={Classes.icon} src={s.image} />
                        {/* <div dangerouslySetInnerHTML={{__html: s.title}} /> */}
                        <p className="smallText">{s.title}</p>
                    </div>
                    )}
                )}
                </div>
            </div>
        </section>
    )
}

export default ThreeUp
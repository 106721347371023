/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 import metaImage from '../images/sppsdk_meta.png'

 
 function SEO({meta}) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
             siteUrl
             keywords
           }
         }
       }
     `
   )
 
   const image = metaImage
   const metaDescription = site.siteMetadata?.description
   const keywords = site.siteMetadata?.keywords
   const title = site.siteMetadata?.title
   return (
     <Helmet
    //    htmlAttributes={{
    //      lang,
    //    }}
       title={title}
       titleTemplate={title}
       meta={[
         {
           name: `description`,
           content: metaDescription,
         },
         {
            name: `keywords`,
            content: keywords,
          },
         {
           property: `og:title`,
           content: title,
         },
         {
           property: `og:description`,
           content: metaDescription,
         },
         {
           property: `og:type`,
           content: `website`,
         },
         {
            property: `og:image`,
            content: image,
          },
         {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:image`,
            content: image,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.author || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
       ].concat(meta)}
     />
   )
 }
 
 SEO.defaultProps = {
   lang: `da`,
   meta: [],
   description: ``,
 }
 
 SEO.propTypes = {
   description: PropTypes.string,
   image: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
 }
 
 export default SEO
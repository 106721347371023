import React, {useRef, useEffect, useState} from "react";
import {
    header,
    logoWrapper,
    logo, 
    cover,
    scrollVertical,
    arrow,
    transparent
} from './header.module.scss'

const Header = () => {


    const [windowHeight, setWindowheight] = useState(typeof window !== "undefined" ? window.innerHeight : null)
    const [scrollPosition, setScrollPosition] = useState(typeof window !== "undefined" ? window.scrollY : null);
    const [wrapperheight, setWrapperheight] = useState();

    let coverHeight = (windowHeight - wrapperheight) / 2;

    const wrapper = useRef();

    const getWrapperHeight = () => {
        const newHeight = wrapper.current.clientHeight;
        setWrapperheight(newHeight);
      };

    const handleResize = () => {
        setWindowheight(typeof window !== "undefined" ? window.innerHeight : null)
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
        window.addEventListener('resize', handleResize)
        }
        getWrapperHeight();
    }, [windowHeight]);

      const handleScroll = () => {
        if (typeof window !== "undefined") {
        setScrollPosition(window.scrollY)
        }
    }

      useEffect(() => {
        if (typeof window !== "undefined") {
        setScrollPosition(window.scrollY)
        window.addEventListener('scroll', handleScroll)
        }
    });


    return (
        <section className={header}>
            <div className="container">
                <div className="row center">
                    <div className="col">
                        <div className={logoWrapper} ref={wrapper}>
                            <div className={logo} />
                        </div>
                    </div>
                </div>
                <p className={["small-text", scrollVertical, scrollPosition > 20 ? "transparent" : null].join(' ')}>Scroll</p>
            </div>
            {scrollPosition < (windowHeight + 50) - coverHeight ?
                <div className={cover} style={{height: `${coverHeight}px`}}/>
            : null }
        </section>
    )
}

export default Header
import React from "react";
import {
    heading,
    email,
    phone,
    workTitle, 
    personName,
    partners,
    linkedInIcon,
    partnersRow
} from './contact.module.scss'


const Contact = ({title, contactPersons, bottomTitle, bottomText}) => {

    console.log(contactPersons.linkedIn, "contactPersons")

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h2 className={["text-center", heading].join(' ')}>{title}</h2>
                    </div>
                </div>
                <div className={["row", partnersRow].join(' ')}>
                    {contactPersons.map((person, i) => {
                        return (
                            <div key={i} className={["col col-xs-12 col-md-4 space-xs-up", partners].join(' ')} >
                                <p className={personName}>{person.name}</p>
                                <p className={workTitle}>{person.title}</p>
                                <a href={"mailto:" + `${person.email}`} className={email}>E <span className="underlineLink">{person.email}</span></a>
                                <a href={"tel:" + `${person.phone}`} className={phone}>T <span className="underlineLink">{person.phone}</span></a>
                                <a href={person.linkedIn} target="_blank"><div className={linkedInIcon} data-sal="fade" data-sal-easing="easeOutExpo" data-sal-duration="2000"/></a>
                            </div>
                        )}
                    )}
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <b>{bottomTitle}</b>
                        <p>{bottomText}</p>
                    </div>
                </div>
            </div>
        </section>
    ) 
}

export default Contact
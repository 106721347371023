import { Link } from "gatsby";
import React, {useState, useEffect} from "react";

// IMPORT CLASSES
import { 
    navbar, 
    langSwitcher,
    horizontalLine,
    logo,
    logoWrapper,
} from "./navbar.module.scss"

const Navbar = () => {

    const [scrollPosition, setScrollPosition] = useState(0);
    // const [scrollPosition, setScrollPosition] = useState(typeof window !== "undefined" ? window.scrollY : null);
    const [windowHWidth, setwindowHWidth] = useState(typeof window !== "undefined" ? window.innerWidth : null)


    const updateScroll = () => {
        if (typeof window !== "undefined") {
            setScrollPosition(window.scrollY)
        }
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener('scroll', updateScroll)
        }
    });


    const handleResize = () => {
        if (typeof window !== "undefined") {
            setwindowHWidth(window.innerWidth)
        }
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener('resize', handleResize)
        }

    }, [windowHWidth]);



    return (
        <nav className={navbar} style={{backgroundColor: scrollPosition < 600 ? "transparent" : null}}>
                <div className={["container", logoWrapper].join(' ')}>
                    {scrollPosition > 550?
                        <div className={logo}/>
                    : null }
                </div>
            <div className={langSwitcher}>
                <Link to="/" activeClassName="active" className="wordBreakNone"> DA</Link>
                <p className={horizontalLine}>|</p>
                <Link to="/en" activeClassName="active" className="wordBreakNone">EN</Link>
            </div>
        </nav>
    )
}

export default Navbar
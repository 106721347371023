import React from "react";
import {
    heading
} from './text.module.scss'

const Text = ({title, text}) => {

    return (
        <section className="smallPaddingTop">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1 className={heading}>{title}</h1>
                    </div>
                    <div className="col col-xs-12">
                        <p>{text}</p>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Text
// extracted by mini-css-extract-plugin
export var email = "contact-module--email--4F32X";
export var fadeInMain = "contact-module--fade-in-main--4hBYa";
export var heading = "contact-module--heading--oEhwn";
export var linkedInIcon = "contact-module--linkedInIcon--3mdc-";
export var mainContent = "contact-module--main-content--S9Gi8";
export var partners = "contact-module--partners--sD1iP";
export var partnersRow = "contact-module--partnersRow--PVaYX";
export var personName = "contact-module--personName--N5S5E";
export var phone = "contact-module--phone--AllfH";
export var workTitle = "contact-module--workTitle--wKVDD";
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
    heading,
    imageWrapper,
    section
} from './imageAside.module.scss'

const ImageAside = ({title, text, image, imageAlt}) => {

    const imageSharp = getImage(image)



    return (
        <section className={section}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <h2 className={heading}>{title}</h2>
                    </div>
                </div>
                <div className="row middle-xs between-xs">
                    <div className="col col-xs-12 col-xl-5">
                        
                        <p>{text}</p>
                    </div>
                    <div className="col col-xs-12 first-xs last-xl col-xl-6" data-sal="fade" data-sal-easing="easeOutExpo" data-sal-duration="500">
                        <GatsbyImage className={imageWrapper} image={imageSharp} alt={imageAlt} />
                    </div>
                </div>
            </div>
        </section>
    )

}

export default ImageAside
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import Navbar from "../components/navbar/navbar"
import Header from "../components/header/header"
import Text from "../components/text/text"
import ImageAside from "../components/imageAside/imageAside"
import Contact from "../components/contact/contact"
import Footer from "../components/footer/footer"

import { useIntl, Link } from "gatsby-plugin-intl"
import ThreeUp from "../components/threeUp/threeUp"

const App = ({data}) => {
  const i18nData = useIntl().messages

  const i18nToArray = (id) => {
    let arr = []
    const obj = i18nData

    for (const p in obj) {
      if(p.includes(id)) {
        const el = p.replace(id + '.', '').split('.')
        arr[Number(el[0])] = {...arr[Number(el[0])], [el[1]]: obj[p]}
      }
    }
    return arr
  }

  const i18n = useIntl().formatMessage

  return (
    <Layout>
      <SEO />
      <Navbar />
      <Header />
      <Text 
        title={i18n({ id: "pages.index.text_1.title" })}
        text={i18n({ id: "pages.index.text_1.text" })}
      />
      <ThreeUp
        title={i18n({ id: "pages.index.services_1.title" })}
        elements={i18nToArray("pages.index.services_1.services")}
      />
      <ImageAside 
        title={i18n({ id: "pages.index.about_1.title" })}
        text={i18n({ id: "pages.index.about_1.text" })}
        image={data.file}
        imageAlt="Founders"
      />
      <Contact 
        title={i18n({ id: "pages.index.contact_1.title"})}
        contactPersons={i18nToArray("pages.index.contact_1.contact_persons")}
        bottomTitle={i18n({ id: "pages.index.contact_1.bottom_title"})}
        bottomText={i18n({ id: "pages.index.contact_1.bottom_text"})}
      />
      <Footer />
    </Layout>
    )
}

export const pageQuery = graphql`
  query {
    file(name: {eq: "SPPS Founder_2_full"}) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 1000
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
  }
`

export default App


// extracted by mini-css-extract-plugin
export var arrow = "header-module--arrow--YjFj2";
export var cover = "header-module--cover--HtAIZ";
export var fadeInMain = "header-module--fade-in-main--vrZfi";
export var header = "header-module--header--4dQNe";
export var logo = "header-module--logo--WAoX3";
export var logoWrapper = "header-module--logoWrapper--IeGRq";
export var mainContent = "header-module--main-content--fCet5";
export var scrollCta = "header-module--scrollCta--U09Ul";
export var scrollVertical = "header-module--scrollVertical--S8xgL";
export var transparent = "header-module--transparent--X8hh-";